import { computed, reactive } from 'vue'
import { mapState, mapMutations, mapActions, useStore } from 'vuex'

const useMapState = (namespace, getKeys) => {
  const store = useStore()

  const storeState = {}
  const storeFns = mapState(namespace, getKeys)

  Object.keys(storeFns).forEach((fnKeys) => {
    const fn = storeFns[fnKeys].bind({ $store: store })
    storeState[fnKeys] = computed(fn)
  })

  return reactive(storeState)
}

const useMapMutations = (namespace, getKeys) => {
  const store = useStore()

  const storeMutations = {}
  const storeFns = mapMutations(namespace, getKeys)

  Object.keys(storeFns).forEach((fnKeys) => {
    const fn = storeFns[fnKeys].bind({ $store: store })
    storeMutations[fnKeys] = fn
  })

  return storeMutations
}

const useMapActions = (namespace, getKeys) => {
  const store = useStore()

  const storeActions = {}
  const storeFns = mapActions(namespace, getKeys)

  Object.keys(storeFns).forEach((fnKeys) => {
    const fn = storeFns[fnKeys].bind({ $store: store })
    storeActions[fnKeys] = fn
  })

  return storeActions
}

export { useMapState, useMapMutations, useMapActions }
