import {
  ref,
  reactive,
  onMounted,
  defineProps,
  defineEmits,
  computed,
  nextTick,
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useMapState, useMapMutations, useMapActions } from './useMapVuex'

const useImport = () => {
  return {
    computed,
    onMounted,
    ref,
    reactive,
    defineProps,
    defineEmits,
    useRouter,
    useRoute,
    useMapState,
    useMapMutations,
    useMapActions,
    nextTick,
  }
}

export default useImport
