// 卫星网关部件
const data = {
  section1: {
    title: '卫星网关部件',
    desc: '',
  },
  section2: {
    img: require('@/assets/device/2.png'),
    headerTitle: 'U高速天基物联网模组',
    title: 'U高速天基物联网模组',
    desc: ``,
  },
  section3: {
    feature: [
      // row
      [
        {
          title: '',
          arr: ['U频段接收和发射通道&以及集成收发滤波器'],
        },
        {
          title: '',
          arr: ['数字基带、ADC、DAC、时钟模块'],
        },
        {
          title: '',
          arr: ['对外通信TTL、SPI双排针接口'],
        },
      ],
    ],
  },
  section4: {
    column: [
      {
        name: '名称',
        key: 'name',
        with: 400,
      },
      {
        name: '规格',
        key: 'standard',
        // with: 600,
      },
    ],
    tableData: [
      {
        name: '模组尺寸',
        standard: '85mm*55mm*9.3mm(误差+0.5mm)',
      },
      {
        name: '电连接器',
        children: [
          {
            name: 'X04 模组发射接口',
            standard: 'I-PEX',
          },
          {
            name: 'X05 模组接收接口',
            standard: 'I-PEX',
          },
          {
            name: 'CN1 模组数据接口',
            standard: 'PIN2.0双排插针',
          },
        ],
      },
    ],
  },
}

export default data
