// MESH自组网设备
// WS-BSDR601机载型设备
const data = {
  section1: {
    title: 'MESH自组网设备',
    desc: '',
  },
  section2: {
    img: require('@/assets/device/8.png'),
    headerTitle: '机载型设备',
    title: 'WS-BSDR601机载型设备',
    desc: `WS-BSDR601机载型设备是我司基于软件通信体系结构（SCA）及多跳自组网技术（MESH）研制的无线通信终端。我司开发并深度优化了物理层波形算法，使产品在接收灵敏度、作用距离、移动性和吞吐率稳定性等方面有了明显的改善。设备以高性能可编程处理器为核心，支持动态加载我司全系列宽/窄带、抗干扰等通信波形，可为用户在复杂环境下迅速搭建可靠的无线链路，为各种业务（如：高清视频、语音、IP数据传输等）提供快速灵活的解决方案。
    <br/><br/>
    该产品适用于军事领域与应急领域，可广泛用于无人机组网、无人机中继、无人机巡航等领域。
    `,
  },
  section3: {
    feature: [
      // row
      [
        {
          title: '高性能传输技术',
          arr: [
            '单载波频域均衡（SCFDE）等系列波形',
            '高性能编译码（Turbo）',
            '分集接收（双接收天线）',
            '传输参数自适应选择（信道学习）',
          ],
        },
        {
          title: '软件通信体系结构（SCA)',
          arr: ['支持多波形加/卸载', '支持第三方用户波形开发和移植'],
        },
        {
          title: '故障管理功能',
          arr: ['自检、运行监视、故障诊断与隔离', '支持无人值守'],
        },
      ],
      // row
      [
        {
          title: '多跳自组网（MESH）技术',
          arr: ['自动组网，无需人工干预', '组网灵活，支持任意网络拓扑'],
        },
        {
          title: '便携易用',
          arr: [
            '支持背负或车载使用',
            '开机即用',
            '电池可拆卸',
            '支持手机、Web等配置功能，可远程管理',
          ],
        },
      ],
    ],
  },
  section4: {
    column: [
      {
        name: '名称',
        key: 'name',
        with: 400,
      },
      {
        name: '规格',
        key: 'standard',
        // with: 600,
      },
    ],
    tableData: [
      {
        name: '无线特性',
        children: [
          {
            name: '频率范围',
            standard: '1420～1470MHz',
          },
          {
            name: '带宽',
            standard: '1/2/5/10/20/40MHz可配置',
          },
          {
            name: '调制方式',
            standard: 'SCFDE + QPSK/16QAM/64QAM(自适应)',
          },
          {
            name: '传输方式',
            standard: '1发2收',
          },
          {
            name: '编译码',
            standard: 'Turbo码(编码效率36%/54%/72%自适应)',
          },
          {
            name: '传输速率',
            standard: `峰值空口速率108Mbps@40MHz
            峰值业务速率72Mbps@40MHz
            `,
          },
          {
            name: '接收灵敏度',
            standard: '-110dBm@1MHz',
          },
          {
            name: '传输距离',
            standrd: `典型场景：城区复杂路面2km 
            郊区开阔路面4km
            `,
          },
          {
            name: '发射功率',
            standard: '2W(支持定制)',
          },
        ],
      },
      {
        name: '组网功能',
        children: [
          {
            name: '组网能力',
            standard: '支持32节点以上',
          },
          {
            name: '组网跳数',
            standard: '>8跳',
          },
          {
            name: '组网时间',
            standard: '冷启动25秒入网，热启动5秒入网',
          },
          {
            name: '网络拓扑',
            standard: '无中心网络、星型网、链式网、网格网等',
          },
          {
            name: '网络透传',
            standard: '支持网络透传功能',
          },
        ],
      },
      {
        name: '用户接口',
        children: [
          {
            name: '射频接口',
            standard: `ANT1：SMA，接收天线
            ANT2：SMA，发射/接收共用天线
            `,
          },
          {
            name: 'GPS/北斗接口',
            standard: `SMA，GPS/北斗天线`,
          },
          {
            name: '网口+串口',
            standard: `网口：航插（企标），百兆
            串口：航插（企标）, RS232
            `,
          },
          {
            name: 'HDMI接口',
            standard: '航插（企标）, HDMI',
          },
          {
            name: '供电接口',
            standrad: '航插（企标）, DC12.5V～16.8V',
          },
        ],
      },
      {
        name: '物理特性',
        children: [
          {
            name: '外形尺寸',
            standard: '≤160mm×100mm×38mm（不含天线接口、GPS接口）',
          },
          {
            name: '整体重量',
            standard: '≤0.9kg',
          },
        ],
      },
      {
        name: '整机功耗',
        children: [
          {
            name: '整机功耗',
            standard: '≤24W@2W常发',
          },
        ],
      },
      {
        name: '环境适应性',
        children: [
          {
            name: '防护等级',
            standard: 'IP65',
          },
          {
            name: '工作温度',
            standard: '-40℃～+60℃',
          },
          {
            name: '存储温度',
            standard: '-55℃～+125℃',
          },
        ],
      },
      {
        name: '其它',
        children: [
          {
            name: '系统升级',
            standard: '支持一键升级、远程升级',
          },
          {
            name: '控制功能',
            standard: '支持网络管理系统平台',
          },
        ],
      },
    ],
  },
}

export default data
