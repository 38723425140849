import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import VueI18n from './language'

import 'element-plus/dist/index.css'
import 'element-plus/es/components/message/style/css'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.scss'
import 'swiper/modules/pagination/pagination.scss'

const app = createApp(App)

app.use(ElementPlus, {
  locale: zhCn,
})

app.use(VueI18n)
app.use(store)
app.use(router)
app.mount('#app')
