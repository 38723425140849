const news = [
  {
    title: '斯北图：践行工业航天，解决星座建设瓶颈',
    img: require('@/assets/news/news-small-1.png'),
    homeImg: require('@/assets/news/1.png'),
    time: '2023-04-26',
    desc: '“重庆号”是鸿雁星座的试验星，由航天科技集团五院深圳航天东方红卫星有限公司（原深圳航天东方红海特卫星有限公司）抓总研制。其功能密度和性能进一步提高，空间环境适应能力更强，为未来商业航天系统批量化低成本研制奠定了基础。',
  },
  {
    title: '获奖名单 | 2023年商业航天年度奖项重磅揭晓',
    img: require('@/assets/news/news-small-2.png'),
    homeImg: require('@/assets/news/2.png'),
    time: '2023-04-25',
    desc: '4月23日晚，“2023商业航天产业国际论坛·航天探索之夜”在安徽合肥顺利举办。',
  },
  {
    title: '斯北图可靠性案例 | 鸿雁星座首发星 1100KM 24小时全时段运行 无失效',
    img: require('@/assets/news/news-small-3.png'),
    homeImg: require('@/assets/news/3.png'),
    time: '2023-04-25',
    desc: `2018年12月，鸿雁星座首发星成功发射，轨道高度1100公里，其辐射环境恶劣，24小时全时段运行3年半，无失效。`,
  },
]

export default news
