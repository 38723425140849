const { VUE_APP_PUBLIC_PATH } = process.env
const path =
  VUE_APP_PUBLIC_PATH === '/' ? '/cases/' : VUE_APP_PUBLIC_PATH + '/cases/'

const getRelativePath = (imgPath: string) => {
  return path + imgPath
}
const cases: any = {
  0: {
    title: '某地震局地震数据监测项目',
    solution:
      '某地震局为了强化地震后的信息收集，通过在居民区楼顶安装压力、位移和加速度等传感器，并针对地震后电力中断的情况，利用卫星物联网通讯技术，将地震后的监测信息迅速、有效地传回给监测中心，为灾后的预防、恢复和重建以及科研工作提供有力的数据支持。',
    solutionImg: getRelativePath('ss-0.png'),
    bannerImg: getRelativePath('sb-0.png'),
    listImg: getRelativePath('s0.png'),
    homeText: '地震监测项目',
    homeImg: require('@/assets/home/dizhen-case.png'),
  },
  1: {
    title: '某牦牛监测项目',
    solution:
      '某牧区为了解决在无电无网放牧地区牦牛的监测难题，通过给牦牛佩戴项圈，基于卫星通信传输方式和卫星网关终端产品，实现了在无电无网放牧地区对牦牛的定位监测信息的回传，进而达到对牦牛进行精细化管理的目的，同时也保证牧民牦牛的资产安全。',
    solutionImg: getRelativePath('ss-1.png'),
    bannerImg: getRelativePath('sb-1.png'),
    listImg: getRelativePath('s1.png'),
    homeText: '牦牛项目',
    homeImg: require('@/assets/home/maoniu-case.png'),
  },
  2: {
    title: '某煤矿水位监测项目',
    solution:
      '某煤矿为获得井下水位监测数据，提高数据采集的时效性与精确性，在矿区周边区域开挖竖井并设置水位传感器，因其位于荒郊野岭，蜂窝网络信号不能完全覆盖，造成数据传输不畅。因此采用卫星通信技术手段，解决在没有蜂窝网络信号覆盖情况下的水位数据回传。',
    solutionImg: getRelativePath('ss-2.png'),
    bannerImg: getRelativePath('sb-2.png'),
    listImg: getRelativePath('s2.png'),
    homeText: '新农村智慧水利',
    homeImg: require('@/assets/home/shuili-case.png'),
  },
  // 林区灯塔不妨在首页
  3: {
    title: '某林区求生灯塔项目',
    solution:
      '某林区为了解决游客在游玩时出现紧急情况而需要求助的问题，在险情易发的区域部署了微星物联的应急求生灯塔。通过应急求生灯塔，游客在没有网络的情况下可以一键拔打求助电话，设备会自动上报求助位置、求生时间，以便于救援人员进行救助。同时，应急求生灯塔还存储了饮用水、急救包等应急设备，方便游客在遇险时自救。',
    solutionImg: getRelativePath('ss-3.png'),
    bannerImg: getRelativePath('sb-3.png'),
    listImg: getRelativePath('s3.png'),
    homeImg: '',
    // 林区灯塔不妨在首页
    filter: true,
    video:
      'http://file-wstaiot-com.oss-cn-shanghai.aliyuncs.com/wstaiot/video/p3.mp4',
  },
}

export default cases
