const { VUE_APP_PUBLIC_PATH } = process.env
const path =
  VUE_APP_PUBLIC_PATH === '/'
    ? '/solution/'
    : VUE_APP_PUBLIC_PATH + '/solution/'

const getRelativePath = (imgPath: string) => {
  return path + imgPath
}

const solutionData: any = {
  0: {
    title: '水利监测',
    pain: '近年来，我国水利事业进入蓬勃发展的新时期，城市水务监管系统比较完善，智慧水平比较高，但偏远地区存在水文监测移动公网覆盖不足、水域监管力度不高等问题。',
    application: [
      {
        title: '杆式水利监测站',
        desc: '杆式水利监测站是采用一体化设计的智能监测设备，设备由水质监测计、流量液位计、雨量计、卫星通信传输模块及太阳能供电系统组成。产品采用高精度传感器以及整体微功耗设计，实现水利水文水质、液位及流量的监测。设备采用太阳能方式供电及卫星通信传输，适用于野外无电无网应用场景。',
        img: getRelativePath('sapp-0-0.png'),
      },
    ],
    solution:
      '采用卫星通信传输方式和卫星网关终端产品，解决在无电无网偏远地区、恶劣天气情况、特殊灾害环境及应急情况下的水环境数据传输问题，能够有效实现实时乃至在线监测，确保数据精准性、同步性、及时性。',
    solutionImg: getRelativePath('ss-0.png'),
    bannerImg: getRelativePath('sb-0.png'),
    listImg: getRelativePath('s0.png'),
    homeImg: getRelativePath('sh-0.png'),
  },
  1: {
    title: '海洋监测',
    pain: '近年来，随着海洋经济活动的增加，导致出现海洋环境污染严重、海洋生态环境不断恶化及灾害频发情况。',
    application: [
      {
        title: '海洋监测浮标测量',
        desc: '海洋监测浮标可实现多种水质参数和气象参数的同时测量，包括溶解氧、pH、ORP、电导率、浊度、风速、风向、空气温湿度和太阳辐射等。数据采集模块通过RS-485总线采集和处理各数字传感器测量数据，并通过卫星通信传输到云端，用户可通过手机APP等多平台获取传感器测量值。',
        img: getRelativePath('sapp-1-0.png'),
      },
    ],
    solution:
      '通过在海洋浮标上安装卫星通信网关及传感器，采用卫星通信技术，解决在海洋无电无网地区的水环境监测数据回传问题，建立更加安全的海洋信息化物联网感知体系，从而提升海洋水环境自动化、智能化监测的能力和水平。',
    solutionImg: getRelativePath('ss-1.png'),
    bannerImg: getRelativePath('sb-1.png'),
    listImg: getRelativePath('s1.png'),
    homeImg: getRelativePath('sh-1.png'),
  },
  2: {
    title: '应急求生',
    pain: '随着野外徒步旅游、户外登山探险等活动的兴起，野外遇险事件时有发生，由于野外无网无电环境，给应急救援工作带来巨大挑战。',
    application: [
      {
        title: '沙漠徒步',
        desc: '随着进入沙漠的人越来越多，沙漠徒步的安全意识也被提上议程。迷失方向和缺水是沙漠中的最大风险，由此可能会导致体能消耗过快、中暑、幻觉等一系列问题。微星物联求生灯台解决方案可以在沙漠中为驴友提供一键求助和应急求助物品，帮助大家安全游玩。',
        img: getRelativePath('sapp-2-0.png'),
      },
      {
        title: '野外探险',
        desc: '近年来，野外探险旅游成了不少都市年轻人热衷的户外运动，很多人都想在工作之余，去亲近大自然，但同时，野外探险也伴随着诸多风险和意外。微星物联求生灯台解决方案可以在野外中为探险者提供一键求助和应急求助物品，帮助大家安全探险。',
        img: getRelativePath('sapp-2-1.png'),
      },
      {
        title: '乡村旅游',
        desc: '“脱贫攻坚，发展乡村旅游是一个重要渠道。微星物联求生灯台解决方案可提高政府有效预防和处置各类旅游突发事件的能力，及时、有效地处置旅游行业中所遇到的各种突发事件，最大限度地减少其危害和影响，增强旅游行业整体防灾抗灾水平和抗风险能力。',
        img: getRelativePath('sapp-2-2.png'),
      },
      {
        title: '巡山护林',
        desc: '林区中，如果没有携带专业设备，人很容易迷失方向。微星物联求生灯台解决方案可为森林公安、护林员提供供一键求助和应急求助物品，帮助他们守好生态安全线。',
        img: getRelativePath('sapp-2-3.png'),
      },
    ],
    solution:
      '结合卫星及通信行业多年技术经验，以问题为导向，建立“求生灯塔”为支点的野外应急救援网络，实现野外远程呼救、远程定位及辅助救援等功能，有效解决野外应急救援中“网络信号缺失，呼救难”、“受困位置不准确，定位难”、“野外物资缺乏，自救难”等痛点，打通野外应急救援“生命通道”。',
    solutionImg: getRelativePath('ss-2.png'),
    bannerImg: getRelativePath('sb-2.png'),
    listImg: getRelativePath('s2.png'),
    homeImg: getRelativePath('sh-2.png'),
  },
  3: {
    title: '畜牧监测',
    pain: '在草原等面积较大、地形复杂的地区，很难准确获取畜牧的位置信息，牲畜易容易被盗或者走丢。',
    application: [
      {
        title: '牦牛监测',
        desc: '以牦牛为例，牦牛佩戴监测设备，设备传输定位、生理特征信息给牧民和管理者，不出户就可以查看牦牛的位置以及发生异常情况。',
        img: getRelativePath('sapp-3-0.png'),
      },
    ],
    solution:
      '通过给野外畜群区域定点安装监测传感器和卫星物联通信网关，解决牲畜群在哪儿的问题，通过卫星通信传输至云端服务器及管理平台，形成可视化数据报告，便于查看和统一管理所有牲畜，帮助管理人员和牧民轻松监测和管理牲畜。',
    solutionImg: getRelativePath('ss-3.png'),
    bannerImg: getRelativePath('sb-3.png'),
    listImg: getRelativePath('s3.png'),
    homeImg: getRelativePath('sh-3.png'),
  },
  4: {
    title: '煤矿监测',
    pain: '煤矿位于城郊或比较偏远的乡村和山区，地形复杂，通信运营商的网络不能全部覆盖，所以在对矿山监控数据进行收集时，可能会发生通信不畅、数据丢失等情况。',
    application: [
      {
        title: '煤矿物联数据监测',
        desc: '在煤矿物联数据监测应用方面。提供煤矿深部位移监测仪、液位仪、渗压计、雨量计、锚索测力计、水位计等煤矿物联传感器数据的卫星通信回传。在煤矿Mesh自组网应急指挥应用方面。建设由手持型、背负型及指挥箱等自组网设备组成的应急通信指挥调度系统，提供任意网络拓扑结构，方便用户在多种环境下的图像、语音的指挥通信。',
        img: getRelativePath('sapp-4-0.png'),
      },
    ],
    solution:
      '构建煤矿行业卫星物联网系统及Mesh自组网通信系统，实现煤矿安全态势监测及现场应急指挥与调度，从而达到对煤矿监测事件的早发现、早预警，以及煤矿语音、图像、数据和定位信息等多媒体信息的“多跳中继”传输，提升应急指挥与通信保障水平。',
    solutionImg: getRelativePath('ss-4.png'),
    bannerImg: getRelativePath('sb-4.png'),
    listImg: getRelativePath('s4.png'),
    homeImg: getRelativePath('sh-4.png'),
  },
  5: {
    title: '地质灾害',
    pain: '目前，我国在全国各地区部署了大量地面灾害监测台站，用于地震和地灾的监测和预警。目前主要基于地面网络进行数据传输，一方面受限于地面网络覆盖有限，很多地区台站采集数据不能及时传输；另一方面很多地灾发生前的次生灾害导致地面网络瘫痪，有价值的数据无法传输。',
    application: [
      {
        title: '地质灾害监测',
        desc: '在山体滑坡方面，提供基准仪、深部测斜监测仪数据的卫星通信回传。在地震监测方面，提供压力、位移、加速度等地震物联传感器数据的卫星通信回传。在泥石流方面，提供地下水位监测仪、雨量监测仪数据的卫星通信回传。',
        img: getRelativePath('sapp-5-0.png'),
      },
    ],
    solution:
      '基于多网融合的多模物联网智能终端，融合GEO卫星、LEO物联网卫星、地面5G蜂窝网、LORAWAN网关等多种通信方式，解决在无电无网的情况下，通过卫星通讯方式向监控中心回传地质灾害监测数据，从而提升地质灾害综合监测服务能力。',
    solutionImg: getRelativePath('ss-5.png'),
    bannerImg: getRelativePath('sb-5.png'),
    listImg: getRelativePath('s5.png'),
    homeImg: getRelativePath('sh-5.png'),
  },
  6: {
    title: '应急指挥',
    pain: '现有信息化装备由于智能化程度不足，缺少空天地协同工作能力，当发生重大灾害和应急救援事件时，往往不能满足应急指挥的需求。',
    application: [
      {
        title: '应急通信',
        desc: '在应急通信方面，提供语音通信、视频会议、视频回传等必要的应急通信保障。在应急救援方面，为无人机、应急通信车提供稳定可靠、大带宽高速通信链路。在防灾减灾方面，构建“全面要素感知+多通信手段传输”的集成网络，搭建“端系统+平台+网络”整体架构。',
        img: getRelativePath('sapp-6-0.png'),
      },
    ],
    solution:
      '卫星通信所具有的覆盖面广、不受地理条件限制、不易受灾害影响的优势，是灾后第一时间通信保障的最有效手段。可借助不同频段、不同轨位的通信卫星，并结合多种通信手段，组成可靠性高、手段多样的天地一体通信系统，在无交通条件、无电力网络、无通信网络下提供通信服务保障。实现话音、图像、数据、互联网接入、视频会议以及视频监控等功能，满足用户统一指挥调度、现场音视频回传、多点视频会议等需求。',
    solutionImg: getRelativePath('ss-6.png'),
    bannerImg: getRelativePath('sb-6.png'),
    listImg: getRelativePath('s6.png'),
    homeImg: getRelativePath('sh-6.png'),
  },
  7: {
    title: '公共安全',
    pain: '当突发事件发生时，在没有信号覆盖的极端情况下，如何解决通信链路建立和视频、语音通信的问题一直是影响公共安全的一个关键问题。',
    application: [
      {
        title: '特警应急通信',
        desc: '当突发事件发生时，面对复杂环境，也可以利用MESH设备方便快速构建应急通信网络系统，各作战单元信息节点通过宽带无线自组网络，可实现敌情信息共享、战场态势感知、军情实时会商、任务即时分配、作战指令下达，进而实现各作战单元一体化协同，实时高效指控。',
        img: getRelativePath('sapp-7-0.png'),
      },
      {
        title: '公安应急通信',
        desc: '安保车队前导车、后援车、通信车上搭载部署无线MESH车载设备，无需预设规划，即可在极短的时间内组建一张多跳中继、多业务、高可靠性、高移动性的宽带无线传输网络。保障多车同时联动通信，为用户提供独立、安全、高效、及时的语音通信、数据传输及视频监控等业务。',
        img: getRelativePath('sapp-7-1.png'),
      },
    ],
    solution:
      '利用自组网设备（ MESH ）自动路由、多跳中继的特点，解决单兵与单兵、车与单兵、车与车间无间断通信问题。在距离不够的时候，通过无人机自组网中继，扩大无线自组网覆盖范围，现场应急指挥车或指挥部且通过卫星链路将音视频图像回传到后方指挥中心，接收后方指挥中心的指令下达。',
    solutionImg: getRelativePath('ss-7.png'),
    bannerImg: getRelativePath('sb-7.png'),
    listImg: getRelativePath('s7.png'),
    homeImg: getRelativePath('sh-7.png'),
  },
}

const template = {
  title: '',
  pain: '',
  application: [
    {
      desc: '',
      img: getRelativePath('sapp-0-0.png'),
    },
  ],
  solution: '',
  solutionimg: getRelativePath('sapp-0-0.png'),
  bannerImg: getRelativePath('sb-0.png'),
}

export default solutionData
