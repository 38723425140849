import { scrollTop } from './assist'

// 滚动到指定标签
export const scrollToAnchor = (anchor: any) => {
  const scroll = document.body.scrollTop || document.documentElement.scrollTop
  const anchorEle: any =
    typeof anchor === 'string' ? document.querySelector(anchor) : anchor
  if (!anchorEle) {
    return
  }
  // 头部标签
  const headerEle: any = document.querySelector('#nav-container')
  console.log(headerEle, 'headerEleheaderEle')
  scrollTop(window, scroll, anchorEle?.offsetTop - headerEle?.offsetHeight)
}

// 将数据map类型转换成数组形式
export const transformMap2List = (mapData: any) => {
  return Object.keys(mapData).map((key: string) => {
    return {
      ...mapData[key],
      id: key,
    }
  })
}
