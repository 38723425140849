// scrollTop animation
export function scrollTop(
  el: any,
  from = 0,
  to: number,
  duration = 500,
  endCallback?: any
) {
  const _window: any = window

  if (!_window.requestAnimationFrame) {
    _window.requestAnimationFrame =
      _window.webkitRequestAnimationFrame ||
      _window.mozRequestAnimationFrame ||
      _window.msRequestAnimationFrame ||
      function (callback: any) {
        return _window.setTimeout(callback, 1000 / 60)
      }
  }
  const difference = Math.abs(from - to)
  const step = Math.ceil((difference / duration) * 50)

  function scroll(start: any, end: any, step: any) {
    if (start === end) {
      endCallback && endCallback()
      return
    }

    let d = start + step > end ? end : start + step
    if (start > end) {
      d = start - step < end ? end : start - step
    }

    if (el === _window) {
      _window.scrollTo(el.scrollX, d)
    } else {
      el.scrollTop = d
    }
    _window.requestAnimationFrame(() => scroll(d, end, step))
  }
  scroll(from, to, step)
}
